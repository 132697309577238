<script>
import Swal from 'sweetalert2';
export default {
  props: { reloadRooms: {}
  },
  data() {
    return {
      rooms : [],
      selectedRows: null,
      totalPages : 0,
      currentPage: 1,
      pageSize: 5,
      pageOptions: [10, 25, 50, 100],
      showModal: false,
      active:false,
    }
  },
  created: function() {
    this.refreshRoomsTable();
  },
  watch : {
    selectedRows(){
        if(this.selectedRows.length && undefined != this.selectedRows[0]){
         this.$emit('selectedRooms',this.selectedRows)
        }else{
          this.$emit('selectedRooms',[])
        }
      },
    reloadRooms:{
        handler(val){
          if(val){
            this.refreshRoomsTable();
          }
        }
      }
  },
  methods: {
    refreshRoomsTable(){
    this.$http.post('/infrastructure/rooms/list')
          .then(res => (this.rooms = res.data.original.list))
          .catch(() => {
              // error.response.status Check status code
              
          }).finally(() => {
              //Perform action in always
          });
    },
    deleteRoom(roomName, roomUid) {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer " + roomName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/infrastructure/rooms/delete/" + roomUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  this.refreshRoomsTable();
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    
  },
};
</script>

<template>
  <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des Infrastructure</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <v-table
                    class="table table-hover"
                    :data="rooms"
                    :currentPage.sync="currentPage"
                    :pageSize="5"
                    @totalPagesChanged="totalPages = $event"
                    selectionMode="single"
                    selectedClass="table-info"
                    @selectionChanged="selectedRows = $event"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Base de Vie</th>
                      <th>N°</th>
                      <th>Bloc</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">
                    <v-tr v-for="row in displayData" :key="row.id"  :row="row">
                      <td>{{row.lifebaseName}}</td>
                      <td>{{row.number}}</td>
                      <td>{{row.blocName}}</td>
                      <td>{{row.type}}</td>
                      <td>{{row.status}}</td>
                      <td><a  @click="deleteRoom(row.number, row.uuid)" class="text-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Delete"><i class="mdi mdi-close font-size-18"></i></a></td>
                    </v-tr>
                  </tbody>
                </v-table>
                <smart-pagination
                  :currentPage.sync="currentPage"
                  :totalPages="totalPages"
                />
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

      
      </div>
    </div>

</template>