<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import roomsTable from './components/restaurantsTable';
import { fetchEquipmentsApi } from "@/api/common";
import { required, maxLength } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { getDropDownElementsByTypeApi } from "@/api/common";

export default {
  page: {
    title: "Restaurants",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, roomsTable,Multiselect },
  data() {
    return {
      reloadRooms:undefined,
      tableData: [],
      blocs: [],
      equipments: [],
      equipmentStatus: [
        {
          id: 'new',
          name: 'Nouveau'
        },
        {
          id: 'used',
          name: 'Utilisé'
        },
      ],
      restaurantTypes: [],
      title: "Restaurants",
      rooms: [],
      selectedRows: null,
      items: [
        {
          text: "Infrastructures",
          href: "javascript:void(0)"
        },
        {
          text: "Restaurants",
          active: true
        }
      ],
      /* filter: null,
      filterOn: [], */
     
      fields: [
        { key: "number", sortable: true },
        { key: "bloc", sortable: true },
        { key: "type", sortable: true },
        { key: "status", sortable: true },
      ],
      lifeBases :[],
      lbl :[],
      restaurantsForm: {
        id :"",
        lifebase_id: "",
        restaurantNumber: "",
        restaurantBloc: "",
        restaurantType: "",
        equipments: [
          {
            equipment: "",
            serial_no: "",
            inventory_no: "",
            status: "",
          },
        ],
        
        // roomPhone: "",
      },
      restaurantsFormRest: {
        id :"",
        lifebase_id: "",
        restaurantNumber: "",
        restaurantBloc: "",
        restaurantType: "",
        equipments: [
          {
            equipment: "",
            serial_no: "",
            inventory_no: "",
            status: "",
          },
        ],
        // roomPhone: "",
      },
      SelectedCategory:undefined,
      disabled : true,
      canDelte : false,
      disabledAdd : false,
      disabledUpdate : false,
      submitted: false,
    };
  },
  validations: {
    restaurantsForm: {
      restaurantNumber: { required, maxLength: maxLength(64) },
      restaurantBloc: { required },
      restaurantType: { required },
      lifebase_id  :{required}
    },
  },
  computed: {

    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    this.totalRows = this.tableData.length;

    this.getLifeBases();
  //  this.refreshRoomsTable();
  },
  created: function() {
    this.getRoomsTypes();
    this.fetchEquipmentsList();
  },
  watch :{
    SelectedCategory:{
        handler(val){
            this.restaurantsForm.restaurantBloc = val;
        }
    },
  },
  methods: {
    
    addBtn(){
      this.disabled = false;
      this.disabledAdd = true;
      this.disabledUpdate = false;
      this.clearForms();
    },
    duplicateBtn(){
      this.disabled = false;
      this.disabledAdd = true;
      this.disabledUpdate = false;
      this.form.region.uuid = "";
      this.form.site.uuid = "";
      this.form.zone.uuid = "";
      this.form.bloc.uuid = "";
      this.form.unit.uuid = "";
    },
    editBtn(){
      this.disabledAdd = false;
      this.disabledUpdate = true;
      this.disabled = false;
    },
    cancelBtn(){
      this.disabled = true;
      this.disabledAdd = false;
      this.disabledUpdate = false;
    },
    getLifeBases(){
      let loader = this.$loading.show({
        color: '#000000',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.5,
        zIndex: 999,
      })
      var _this = this
      this.$http.get('/base/lifebases/index')
      .then(response => {
          _this.lbl = response.data;
          loader.hide();
      })
      .catch(error => console.log(error))
    },

    deleteRow(){

    },
    AddformData() {
      if (this._validateRoomEquipmentData()) {
        this.restaurantsForm.equipments.push({
          equipment: "",
          serial_no: "",
          inventory_no: "",
          status: "",
        });
      }
    },
    _validateRoomEquipmentData() {
      var valid = true;
      var roomEquipments = this.restaurantsForm.equipments;
      roomEquipments.forEach((singleEquipmentEntry, index) => {
        index++;
        if (singleEquipmentEntry.equipment == "") {
          this.$toast.warning(
            "Veuillez selectionner l'équipement dans la line N°: " + index
          );
          valid = false;
        }

        if (singleEquipmentEntry.serial_no == "") {
          this.$toast.warning(
            "N° de série est obligatoire dans la line N°: " +
              index
          );
          valid = false;
        }

        if (singleEquipmentEntry.inventory_no == "") {
          this.$toast.warning(
            "N° d'inventaire est obligatoire dans la line N°: " +
              index
          );
          valid = false;
        }

        if (singleEquipmentEntry.status == "") {
          this.$toast.warning(
            "Status obligatoire dans la line N°: " +
              index
          );
          valid = false;
        }
      });

      return valid;
    },
    refreshRoomsTable(){
    this.$http.post('/infrastructure/rooms/list')
          .then(res => (this.rooms = res.data.original.list))
          .catch(() => {
              // error.response.status Check status code
              
          }).finally(() => {
              //Perform action in always
          });
    },
    /* onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }, */
    
    fetchEquipmentsList() {
      fetchEquipmentsApi()
        .then((res) => (this.equipments = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    getBlocs() {
      var _this = this;
      this.$http
        .post("/infrastructure/blocs/list",{'life_base_id':this.restaurantsForm.lifebase_id})
        .then((res) => {
          _this.blocs = res.data.original.list;
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },

    getRoomsTypes() {
      var _this = this;
      getDropDownElementsByTypeApi("hosting_room_classification")
        .then((res) => {
          _this.restaurantTypes = res.data.original.list;
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
    selectedRooms(selectedRooms){
        this.clearForms
        this.disabled       = true;
        this.disabledAdd    = false
        this.disabledUpdate = false
      if(selectedRooms.length> 0 &&  undefined != selectedRooms[0]){
        this.restaurantsForm.id          = selectedRooms[0].id;
        this.restaurantsForm.uuid        = selectedRooms[0].uuid;
        this.restaurantsForm.lifebase_id = selectedRooms[0].lifebase_id;
        this.getBlocs()
        this.restaurantsForm.restaurantNumber = selectedRooms[0].number;
        this.SelectedCategory    = selectedRooms[0].bloc
        this.restaurantsForm.restaurantType   = selectedRooms[0].restaurantType;

        this.restaurantsForm.equipments = selectedRooms[0].equipments;
        this.restaurantsForm.equipments.forEach( element => {
            element.status = this.equipmentStatus.filter(x => x.id == element.status ); console.log(this.equipmentStatus.filter(x => x.id == element.status ));
        })
      }
    },
    clearForms(){
      this.restaurantsForm   = this.restaurantsFormRest
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$http
          .post("/infrastructure/rooms/store", this.restaurantsForm)

          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                Swal.fire("Ajouter!", res.data.original.msg, "success");
                this.reloadRooms = true
                break;

              case 500:
                Swal.fire("Erreur!", res.data.original.msg, "error");
                break;
            }
            this.reloadRooms = false
          })
          .catch((error) => {
            this.reloadRooms = false
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
    
    deleteRoom() {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer " + this.restaurantsForm.restaurantNumber + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/infrastructure/rooms/delete/" + this.restaurantsForm.uuid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  this.reloadRooms = false
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
            this.reloadRooms = false
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    updateRoom() {
      this.submitted = true;
      this.$v.$touch();
      if(!this.$v.$invalid){
      this.$http
        .post("/infrastructure/rooms/update", this.restaurantsForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              Swal.fire("Modifier!", res.data.original.msg, "success");
                this.reloadRooms = true
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
              this.$toast.error(error.message);
        })
        .finally(() => {});
      }
    },
  }
};
</script>
<style >
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
  #StickyH {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #097EA9 ;
  }
  .btn-editH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteH #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-printH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <rooms-table :reloadRooms="reloadRooms" @selectedRooms="selectedRooms" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <ul id="StickyH">
              <li>
                <button type="button" @click="addBtn" class="btn btn-addH rounded-pillH float-right mr-1 mt-1">
                <i class="fas fa-plus text-white"></i>
                </button>
              </li>
              <li>
                <button type="button" @click="duplicateBtn" class="btn btn-duplicateH rounded-pillH float-right mr-1 mt-1">
                <i class="far fa-copy text-white"></i>
                </button>
              </li>
              <li>
                <button type="button" @click="editBtn" class="btn btn-editH rounded-pillH float-right mr-1 mt-1">
                  <i class="fas fa-pencil-alt text-white" style=""></i>
                </button>
              </li>
              <li>
                <button type="button" @click="editBtn" class="btn btn-deleteH rounded-pillH float-right mr-1 mt-1">
                    <i class="far fa-trash-alt text-white"></i>
                </button>
              </li>
              <li>
                <button type="button"  class="d-none btn btn-uploadH rounded-pillH float-right mr-1 mt-1">
                <i class="fas fa-file-upload  text-white"></i>
                </button>
              </li>
              <li>
                <button type="button"  class="d-none btn btn-downloadH rounded-pillH float-right mr-1 mt-1">
                <i class="fas fa-file-download text-white"></i>
                </button>
              </li>
              <li>
                <button type="button"  class="d-none btn btn-printH rounded-pillH float-right mr-1 mt-1">
                <i class="fas fa-print"></i>
                </button>
              </li>
            </ul>
            <h4 class="card-title  pb-2 col-12">
              Chambres Details
               <div class="button-items float-right ">
                  <button
                    class="btn btn-primary float-right btn-label"
                    type="button"
                    @click="formSubmit"
                    v-show="disabledAdd"
                  >
                    <i class="fas fa-check label-icon "></i> Enregistrer
                  </button>
                  <button
                    class="btn btn-primary float-right btn-label"
                    type="button"
                    @click="updateRoom"
                    v-show="disabledUpdate"
                  >
                    <i class="fas fa-check label-icon "></i> Enregistrer
                  </button>
                </div>
            </h4>
            <fieldset :disabled="disabled">
              <div class="col-12 row">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Base De Vie</label>
                    <select @change="getBlocs()"
                      v-model="restaurantsForm.lifebase_id"
                      :class="{
                        'is-invalid': submitted && $v.restaurantsForm.lifebase_id.$error,
                      }"
                      class="custom-select"
                    >
                      <option selected disabled>Sélectionner une base </option>
                      <option
                        v-for="lifeBase in lbl"
                        v-bind:value="lifeBase.id"
                        v-bind:key="lifeBase.id"
                      >
                        {{ lifeBase.name }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.restaurantsForm.lifebase_id.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.restaurantsForm.lifebase_id.required"
                        >Le Base De Vie est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Bloc</label>
                    <select
                      v-model="SelectedCategory"
                      :class="{
                        'is-invalid': submitted && $v.restaurantsForm.restaurantBloc.$error,
                      }"
                      class="custom-select"
                    >
                      <option selected>Sélectionner une classe</option>
                      <option
                        v-for="bloc in blocs"
                        v-bind:value="bloc.id"
                        v-bind:key="bloc.id"
                      >
                        {{ bloc.name }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.restaurantsForm.restaurantBloc.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.restaurantsForm.restaurantBloc.required"
                        >Le bloc est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Type</label>
                    <select
                      v-model="restaurantsForm.restaurantType"
                      :class="{
                        'is-invalid': submitted && $v.restaurantsForm.restaurantType.$error,
                      }"
                      class="custom-select"
                    >
                      <option selected></option>
                      <option
                        v-for="type in restaurantTypes"
                        v-bind:value="type.id"
                        v-bind:key="type.id"
                      >
                        {{ type.designation }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.restaurantsForm.restaurantType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.restaurantsForm.restaurantType.required"
                        >Le type est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="deptName">Numéro de chambre</label>
                    <input
                      id="deptName"
                      v-model="restaurantsForm.restaurantNumber"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{
                        'is-invalid':
                          submitted && $v.restaurantsForm.restaurantNumber.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.restaurantsForm.restaurantNumber.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.restaurantsForm.restaurantNumber.required"
                        >Le numéro de chambre est obligatoire.</span
                      >
                      <span v-if="!$v.restaurantsForm.restaurantNumber.maxLength"
                        >La taille du champs est limitée a 64 charactères.</span
                      >
                    </div>
                  </div>
                </div>
                
              </div>
              <div class="col-12 row">
                <div class="col-md-12">
                  <h3>Fiche d'inventaire:</h3>
                  <hr />
                  <div>
                    <div
                      v-for="(equipment, index) in restaurantsForm.equipments"
                      :key="equipment.id"
                      class="row"
                    >
                      <div class="form-group col-lg-3">
                        <label for="message">Equipement</label>
                        <multiselect
                          v-model="restaurantsForm.equipments[index]"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="equipments"
                          placeholder="Selectionner"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleMealType"
                            slot-scope="{ type }"
                            >{{ type.name }}</template
                          >
                        </multiselect>
                      </div>
                      <div class="form-group col-lg-3">
                        <label for="resume">N° Série</label>
                      <input
                          id="subject"
                          v-model="equipment.serial_no"
                          type="text"
                          class="form-control"
                        />
                      </div>

                      <div class="form-group col-lg-2">
                        <label for="subject">N° Inventaire</label>
                        <input
                          id="subject"
                          v-model="equipment.inventory_no"
                          type="text"
                          class="form-control"
                        />
                      </div>

                      <div class="form-group col-lg-3">
                        <label for="message">Status</label>
                        <multiselect
                          v-model="equipment.status"
                          :searchable="true"
                          track-by="id"
                          label="name"
                          :options="equipmentStatus"
                          placeholder="Selectionner"
                          :allow-empty="false"
                        >
                          <template
                            slot="singleMealType"
                            slot-scope="{ type }"
                            >{{ type.name }}</template
                          >
                        </multiselect>
                      </div>

                      <div class="col-lg-1 align-self-center">
                        <a
                          v-if="restaurantsForm.equipments.length > 1 && index != 0"
                          href="javascript:void(0)"
                          ><i
                            class="bx bx-trash-alt"
                            @click="deleteRow(index)"
                          ></i
                        ></a>
                      </div>
                    </div>
                    <input
                      type="button"
                      class="btn btn-success mt-3 mt-lg-0"
                      value="Ajouter"
                      @click="AddformData"
                    />
                  </div>
                </div>
              </div> <br>
              <div class="col-12 row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="formrow-inputCity">Commentaires</label>
                    <textarea
                      class="form-control"
                      v-model="restaurantsForm.notes"
                      cols="30"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>